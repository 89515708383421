/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { providers, Signer, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Web3Modal, { IProviderOptions } from "web3modal";
import ArtBoxDeploymentGanache from "./deployments/ganache/ArtBox.json";
import ArtBoxDeploymentMumbai from "./deployments/mumbai/ArtBox.json";
import ArtBoxDeploymentEthereum from "./deployments/mainnet/ArtBox.json";
import ArtBoxDeploymentRopsten from "./deployments/mainnet/ArtBox.json";
import ArtBoxDeploymentPolygon from "./deployments/polygon/ArtBox.json";
import ArtBoxDeploymentBSC from "./deployments/bsc/ArtBox.json";
import ArtBoxDeploymentArbitrum from "./deployments/arbitrum/ArtBox.json";
import ArtBoxDeploymentFantom from "./deployments/fantom/ArtBox.json";
import ArtBoxDeploymentHarmony from "./deployments/harmony/ArtBox.json";
import { ArtBox } from "./typechain/ArtBox";
import { ArtBox__factory } from "./typechain/factories/ArtBox__factory";
import { ERC721 } from "./typechain/ERC721";
import { ERC721__factory } from "./typechain/factories/ERC721__factory";

type deployment =
  | typeof ArtBoxDeploymentGanache
  | typeof ArtBoxDeploymentEthereum
  | typeof ArtBoxDeploymentMumbai
  | typeof ArtBoxDeploymentPolygon
  | typeof ArtBoxDeploymentBSC
  | typeof ArtBoxDeploymentArbitrum
  | typeof ArtBoxDeploymentFantom
  | typeof ArtBoxDeploymentRopsten
  | typeof ArtBoxDeploymentHarmony;

const emptyContract = {
  instance: undefined,
  factory: undefined,
};
const defaultProvider: providers.Provider | undefined = undefined;
export const ProviderContext = React.createContext<
  [
    providers.Provider | undefined,
    React.Dispatch<React.SetStateAction<providers.Provider | undefined>>
  ]
>([defaultProvider, () => {}]);
const defaultCurrentAddress: string = "";
export const CurrentAddressContext = React.createContext<
  [string, React.Dispatch<React.SetStateAction<string>>]
>([defaultCurrentAddress, () => {}]);
const defaultSigner: Signer | undefined = undefined;
export const SignerContext = React.createContext<
  [Signer | undefined, React.Dispatch<React.SetStateAction<Signer | undefined>>]
>([defaultSigner, () => {}]);
const defaultSymfoniContext: SymfoniContextInterface = {
  currentHardhatProvider: "",
  init: () => {
    throw Error("Symfoni context not initialized");
  },
  loading: false,
  messages: [],
  providers: [],
  network: "hardhat",
  currency: "eth",
};
export const SymfoniContext = React.createContext<SymfoniContextInterface>(
  defaultSymfoniContext
);
export const ArtBoxContext = React.createContext<SymfoniArtBox>(emptyContract);
export const ERC721Context = React.createContext<SymfoniERC721>(emptyContract);

export interface SymfoniContextInterface {
  init: (provider?: string) => void;
  loading: boolean;
  messages: string[];
  currentHardhatProvider: string;
  providers: string[];
  network: string;
  currency: string;
}

export interface SymfoniProps {
  autoInit?: boolean;
  showLoading?: boolean;
  loadingComponent?: React.ReactNode;
  network?: string;
  currency?: string;
}

export interface SymfoniArtBox {
  instance?: ArtBox;
  factory?: ArtBox__factory;
}

export interface SymfoniERC721 {
  instance?: ERC721;
  factory?: ERC721__factory;
}

export const Symfoni: React.FC<SymfoniProps> = ({
  showLoading = true,
  autoInit = true,
  network = "ganache",
  currency = "eth",
  ...props
}) => {
  const [initializeCounter, setInitializeCounter] = useState(0);
  const [currentHardhatProvider, setCurrentHardhatProvider] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const [signer, setSigner] = useState<Signer | undefined>(defaultSigner);
  const [provider, setProvider] = useState<providers.Provider | undefined>(
    defaultProvider
  );
  const [currentAddress, setCurrentAddress] = useState<string>(
    defaultCurrentAddress
  );
  const [fallbackProvider] = useState<string | undefined>(undefined);
  const [providerPriority, setProviderPriority] = useState<string[]>([
    "web3modal",
    "hardhat",
  ]);
  const [ArtBox, setArtBox] = useState<SymfoniArtBox>(emptyContract);
  const [ERC721, setERC721] = useState<SymfoniERC721>(emptyContract);
  useEffect(() => {
    if (messages.length > 0) console.debug(messages.pop());
  }, [messages]);
  const [deployment, setDeployment] = useState<deployment>(
    ArtBoxDeploymentGanache
  );

  const getProvider = async (): Promise<
    { provider: providers.Provider; hardhatProviderName: string } | undefined
  > => {
    let hardhatProviderName = "Not set";
    let _providerPriority = [...providerPriority];
    // Fallback provider
    if (fallbackProvider && autoInit && initializeCounter === 0) {
      if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER") === null) {
        _providerPriority = _providerPriority.sort((a, b) => {
          return a === fallbackProvider ? -1 : b === fallbackProvider ? 1 : 0;
        });
      }
    }
    const provider = await _providerPriority.reduce(
      async (
        maybeProvider: Promise<providers.Provider | undefined>,
        providerIdentification
      ) => {
        let foundProvider = await maybeProvider;
        if (foundProvider) {
          return Promise.resolve(foundProvider);
        } else {
          switch (providerIdentification.toLowerCase()) {
            case "web3modal":
              try {
                const provider = await getWeb3ModalProvider();
                const web3provider = new ethers.providers.Web3Provider(
                  provider
                );
                hardhatProviderName = "web3modal";
                return Promise.resolve(web3provider);
              } catch (error) {
                return Promise.resolve(undefined);
              }
            case "hardhat":
              try {
                const provider = new ethers.providers.JsonRpcProvider({
                  url: "http://127.0.0.1:8545",
                });
                hardhatProviderName = "hardhat";
                return Promise.resolve(provider);
              } catch (error) {
                return Promise.resolve(undefined);
              }
            default:
              return Promise.resolve(undefined);
          }
        }
      },
      Promise.resolve(undefined)
    ); // end reduce
    return provider ? { provider, hardhatProviderName } : undefined;
  };
  const getSigner = async (
    _provider: providers.Provider,
    hardhatProviderName: string
  ): Promise<Signer | undefined> => {
    switch (hardhatProviderName) {
      case "web3modal":
        const web3provider = _provider as ethers.providers.Web3Provider;
        return await web3provider.getSigner();
      case "hardhat":
        return ethers.Wallet.fromMnemonic(
          "test test test test test test test test test test test junk"
        ).connect(_provider);
      default:
        return undefined;
    }
  };
  const getWeb3ModalProvider = async (): Promise<any> => {
    const providerOptions: IProviderOptions = {};
    const web3Modal = new Web3Modal({
      // network: "mainnet",
      cacheProvider: false,
      providerOptions, // required
    });
    return await web3Modal.connect();
  };

  useEffect(() => {
    let subscribed = true;
    const doAsync = async () => {
      const finish = (text: string) => {
        setLoading(false);
        setMessages((old) => [...old, text]);
      };
      const finishWithContracts = async (text: string) => {
        setArtBox(await getArtBox(_provider, _signer));
        setERC721(getERC721(_provider, _signer));
        finish(text);
      };
      if (!autoInit && initializeCounter === 0)
        return finish("Auto init turned off.");
      setLoading(true);
      setMessages((old) => [...old, "Initiating Symfoni React"]);
      const providerObject = await getProvider(); // getProvider can actually return undefined, see issue https://github.com/microsoft/TypeScript/issues/11094

      if (!subscribed || !providerObject)
        return finish("No provider or signer.");
      const _provider = providerObject.provider;
      setProvider(_provider);
      setMessages((old) => [
        ...old,
        "Useing " + providerObject.hardhatProviderName,
      ]);
      setCurrentHardhatProvider(providerObject.hardhatProviderName);
      const _signer = await getSigner(
        _provider,
        providerObject.hardhatProviderName
      );

      if (!subscribed || !_signer)
        return finishWithContracts("Provider, without signer.");
      setSigner(_signer);
      setMessages((old) => [...old, "Useing signer"]);
      const address = await _signer.getAddress();

      if (!subscribed || !address)
        return finishWithContracts("Provider and signer, without address.");
      setCurrentAddress(address);

      return finishWithContracts("Completed Symfoni context initialization.");
    };
    doAsync();
    return () => {
      subscribed = false;
    };
  }, [initializeCounter]);

  const getArtBox = async (_provider: providers.Provider, _signer?: Signer) => {
    let contractAddress: string;
    contractAddress = ArtBoxDeploymentGanache.receipt.contractAddress;

    console.log("Current network: " + network);
    switch (network) {
      case "ganache":
        contractAddress = ArtBoxDeploymentGanache.receipt.contractAddress;
        break;
      case "ethereum":
        contractAddress = ArtBoxDeploymentEthereum.receipt.contractAddress;
        break;
      case "ropsten":
        contractAddress = ArtBoxDeploymentRopsten.receipt.contractAddress;
        break;
      case "polygon":
        contractAddress = ArtBoxDeploymentPolygon.receipt.contractAddress;
        break;
      case "mumbai":
        contractAddress = ArtBoxDeploymentMumbai.receipt.contractAddress;
        break;
      case "fantom":
        contractAddress = ArtBoxDeploymentFantom.receipt.contractAddress;
        break;
      case "bsc":
        contractAddress = ArtBoxDeploymentBSC.receipt.contractAddress;
        break;
      case "arbitrum":
        contractAddress = ArtBoxDeploymentArbitrum.receipt.contractAddress;
        break;
      case "harmony":
        contractAddress = ArtBoxDeploymentHarmony.receipt.contractAddress;
        break;

      default:
        contractAddress = ArtBoxDeploymentGanache.receipt.contractAddress;
    }
    const instance = _signer
      ? ArtBox__factory.connect(contractAddress, _signer)
      : ArtBox__factory.connect(contractAddress, _provider);
    const contract: SymfoniArtBox = {
      instance: instance,
      factory: _signer ? new ArtBox__factory(_signer) : undefined,
    };
    return contract;
  };
  const getERC721 = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? ERC721__factory.connect(ethers.constants.AddressZero, _signer)
      : ERC721__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniERC721 = {
      instance: instance,
      factory: _signer ? new ERC721__factory(_signer) : undefined,
    };
    return contract;
  };
  const handleInitProvider = (provider?: string) => {
    if (provider) {
      setProviderPriority((old) =>
        old.sort((a, b) => {
          return a === provider ? -1 : b === provider ? 1 : 0;
        })
      );
    }
    setInitializeCounter(initializeCounter + 1);
  };
  return (
    <SymfoniContext.Provider
      value={{
        init: (provider) => handleInitProvider(provider),
        providers: providerPriority,
        currentHardhatProvider,
        loading,
        messages,
        network,
        currency,
      }}
    >
      <ProviderContext.Provider value={[provider, setProvider]}>
        <SignerContext.Provider value={[signer, setSigner]}>
          <CurrentAddressContext.Provider
            value={[currentAddress, setCurrentAddress]}
          >
            <ArtBoxContext.Provider value={ArtBox}>
              <ERC721Context.Provider value={ERC721}>
                {showLoading && loading ? (
                  props.loadingComponent ? (
                    props.loadingComponent
                  ) : (
                    <div>
                      {messages.map((msg, i) => (
                        <p key={i}>{msg}</p>
                      ))}
                    </div>
                  )
                ) : (
                  props.children
                )}
              </ERC721Context.Provider>
            </ArtBoxContext.Provider>
          </CurrentAddressContext.Provider>
        </SignerContext.Provider>
      </ProviderContext.Provider>
    </SymfoniContext.Provider>
  );
};
