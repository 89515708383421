import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ArtBoxContext } from "./../hardhat/SymfoniContextStatic";
import { useCallbackRef } from "use-callback-ref";

interface Props {}

type Coordinates = {
  x: number;
  y: number;
};

export const Main: React.FC<Props> = () => {
  const size = 896;
  let boxPosition = React.useRef<Coordinates>({
    x: 0,
    y: 0,
  });

  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const canvasObj = canvasRef.current;
  const [context, setContext] = React.useState<
    CanvasRenderingContext2D | null | undefined
  >(null);
  const history = useHistory();
  const [, forceUpdate] = useState();
  const artbox = useContext(ArtBoxContext);
  const boxes = useCallbackRef<any>([], (_boxes) => forceUpdate(_boxes));

  React.useEffect(() => {
    const internalPixelSize = 2;
    const pixelSize = 32;
    const canvasSize = size * pixelSize;

    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext("2d");
      setContext(renderCtx);
    }

    if (canvasObj && canvasRef.current && context) {
      canvasObj.addEventListener("mousedown", handleMouseDown);
      canvasObj.addEventListener("mouseup", handleMouseUp);
    }

    // Mark the grid
    if (context) {
      context.beginPath();
      context.strokeStyle = "#FAFAFA";
      context.lineWidth = 1;
      let step = 1;
      for (let i = 0; i < size + 1; i++) {
        step = i * pixelSize;
        context.moveTo(step, 0);
        context.lineTo(step, canvasSize);
        context.moveTo(0, step);
        context.lineTo(canvasSize, step);
      }
      context.stroke();
    }

    const doAsync = async () => {
      if (!artbox.instance) return;
      if (!context) return;

      try {
        boxes.current = await artbox.instance.getBoxes();

        for (let i = 0, leni = boxes.current.length; i < leni; i++) {
          const _box = boxes.current[i];
          const _offsetX = parseInt(_box.x) * pixelSize;
          const _offsetY = parseInt(_box.y) * pixelSize;
          for (let j = 0, lenj = _box.box.length; j < lenj; j++) {
            for (let k = 0, lenk = _box.box[j].length; k < lenk; k++) {
              try {
                let _currentColor: string;
                const _colorSource = _box.box[j][k]
                  .toString(16)
                  .padStart(6, "0");
                if (_colorSource === "0" || _colorSource === "1") {
                  _currentColor = "#000000";
                } else {
                  _currentColor = "#" + _colorSource;
                }
                context!.fillStyle = _currentColor;
                context!.fillRect(
                  _offsetX + j * internalPixelSize,
                  _offsetY + k * internalPixelSize,
                  internalPixelSize,
                  internalPixelSize
                );
                context!.imageSmoothingEnabled = false;
              } catch (e) {
                console.log(e);
              }
            }
          }
        }
      } catch (error) {
        console.log("Not connected, validate network...");
      }
    };
    doAsync();

    function handleMouseDown(evt: MouseEvent) {
      boxPosition.current = {
        x: Math.floor(evt.offsetX / pixelSize),
        y: Math.floor(evt.offsetY / pixelSize),
      };

      history.push(
        "/artbox?x=" + boxPosition.current.x + "&y=" + boxPosition.current.y
      );
    }

    function handleMouseUp(evt: MouseEvent) {}

    return function cleanup() {
      if (canvasObj) {
        canvasObj.removeEventListener("mousedown", handleMouseDown);
        canvasObj.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [context, history, boxes, artbox.instance, canvasObj]);

  return (
    <div className="App">
      <canvas
        id="canvas"
        ref={canvasRef}
        width={size}
        height={size}
        className="canvas border"
      ></canvas>
    </div>
  );
};
