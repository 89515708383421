import React, { useState } from "react";
import "./App.css";
import Logo from "./logo.png";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Main } from "./components/Main";
import { ArtBox } from "./components/ArtBox";
import { Profile } from "./components/Profile";
import { Stats } from "./components/Stats";
import { Symfoni } from "./hardhat/SymfoniContextStatic";

type ChainConfiguration = {
  chainName: string;
  rpcUrl: string;
  chainId: number;
  nativeCurrency: {
    name: string;
    decimals: number;
    symbol: string;
  };
  explorer: string;
};

function App() {
  const networks = ["fantom", "polygon", "bsc", "harmony"];
  const devNetworks = ["ethereum", "arbitrum", "ropsten", "mumbai", "ganache"];
  const allNetworks = [...networks, ...devNetworks];
  const [currentNetwork, setCurrentNetwork] = useState(startUrl());
  const ethereum = window.ethereum;

  React.useEffect(() => {
    const doAsync = async () => {
      try {
        if (typeof ethereum === "undefined") {
          alert(
            "This page uses web3, please make sure Metamask is installed and that you are connected to the right network"
          );
          return;
        }

        const network = getChainConfigration(currentNetwork);
        if (network) {
          const appChainId = network.chainId;
          const metamaskChainId = parseInt(ethereum.chainId, 16);
          if (appChainId !== metamaskChainId) {
            metamaskSwitchNetwork(currentNetwork, false);
          }
        }
      } catch (error) {
        console.log("Not connected, switch to the correct network...");
      }
    };
    doAsync();
  });

  function getChainConfigration(_network: string) {
    let net: ChainConfiguration;

    switch (_network) {
      case "ethereum":
        net = {
          chainName: "Ethereum Mainnet",
          rpcUrl:
            "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          chainId: 1,
          nativeCurrency: {
            name: "ETH",
            decimals: 18,
            symbol: "ETH",
          },
          explorer: "https://etherscan.io",
        };
        return net;
      case "ropsten":
        net = {
          chainName: "Ropsten test net",
          rpcUrl:
            "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          chainId: 3,
          nativeCurrency: {
            name: "ETH",
            decimals: 18,
            symbol: "ETH",
          },
          explorer: "https://ropsten.etherscan.io",
        };
        return net;
      case "bsc":
        net = {
          chainName: "Binance smart chain Mainnet",
          rpcUrl: "https://bsc-dataseed.binance.org/",
          chainId: 56,
          nativeCurrency: {
            name: "BNB",
            decimals: 18,
            symbol: "BNB",
          },
          explorer: "https://bscscan.com",
        };
        return net;
      case "polygon":
        net = {
          chainName: "Polygon Mainnet",
          rpcUrl: "https://rpc-mainnet.maticvigil.com",
          chainId: 137,
          nativeCurrency: {
            name: "MATIC",
            decimals: 18,
            symbol: "MATIC",
          },
          explorer: "https://explorer-mainnet.maticvigil.com/",
        };
        return net;
      case "fantom":
        net = {
          chainName: "Fantom Mainnet",
          rpcUrl: "https://rpcapi.fantom.network",
          chainId: 250,
          nativeCurrency: {
            name: "FTM",
            decimals: 18,
            symbol: "FTM",
          },
          explorer: "https://ftmscan.com/",
        };
        return net;
      case "ganache":
        net = {
          chainName: "Ganache test net",
          rpcUrl: "http://localhost:7545",
          chainId: 1337,
          nativeCurrency: {
            name: "ETH",
            decimals: 18,
            symbol: "ETH",
          },
          explorer: "",
        };
        return net;
      case "mumbai":
        net = {
          chainName: "Polygon (mumbai) test net",
          rpcUrl: "https://rpc-mumbai.matic.today",
          chainId: 80001,
          nativeCurrency: {
            name: "MATIC",
            decimals: 18,
            symbol: "MATIC",
          },
          explorer: "https://explorer-mumbai.maticvigil.com/",
        };
        return net;
      case "harmony":
        net = {
          chainName: "Harmony one Mainnet",
          rpcUrl: "https://api.harmony.one",
          chainId: 1666600000,
          nativeCurrency: {
            name: "ONE",
            decimals: 18,
            symbol: "ONE",
          },
          explorer: "https://explorer.harmony.one/#/",
        };
        return net;
      case "arbitrum":
        net = {
          chainName: "Arbitrum Mainnet",
          rpcUrl: "https://arb1.arbitrum.io/rpc",
          chainId: 42161,
          nativeCurrency: {
            name: "ETH",
            decimals: 18,
            symbol: "ETH",
          },
          explorer: "https://arb1.arbitrum.io/rpc",
        };
        return net;
    }
  }

  async function metamaskSwitchNetwork(
    _network: string,
    _reload: boolean = true
  ) {
    console.log(_network);
    if (window.ethereum) {
      const chain: ChainConfiguration = getChainConfigration(_network)!;
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chain.chainId.toString(16)}` }],
        });

        if (_reload) window.location.href = `/${_network}`;
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: chain.chainName,
                  chainId: `0x${chain.chainId.toString(16)}`,
                  rpcUrls: [chain.rpcUrl],
                  nativeCurrency: {
                    name: chain.nativeCurrency.name,
                    decimals: chain.nativeCurrency.decimals,
                    symbol: chain.nativeCurrency.symbol,
                  },
                },
              ],
              blockExplorerUrls: [chain.explorer],
            });
            window.location.href = `/${_network}`;
          } catch (addError) {
            console.log(addError.message);
          }
        }
      }
    }
  }

  function startUrl() {
    const path = window.location.href;
    for (let i = 0; i < allNetworks.length; i++) {
      const network = allNetworks[i];
      if (path.includes(network)) {
        return network;
      }
    }
    return "fantom";
  }

  function generateNetworkOptions() {
    const options = [];

    for (let i = 0; i < networks.length; i++) {
      options.push(
        <option key={networks[i]} value={networks[i]}>
          {networks[i]}
        </option>
      );
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      for (let i = 0; i < devNetworks.length; i++) {
        options.push(
          <option key={devNetworks[i]} value={devNetworks[i]}>
            {devNetworks[i]}
          </option>
        );
      }
    }
    return options;
  }

  return (
    <Router basename={`/${currentNetwork}`}>
      <div>
        <div>
          <header>
            <div className="px-3 py-2 bg-white text-black">
              <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                  <Link
                    to="/"
                    className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-black text-decoration-none"
                  >
                    <img src={Logo} alt="Logo" className="App-logo App-pulse" />
                  </Link>

                  <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                    <li>
                      <select
                        className="form-select"
                        onChange={(_evt) => {
                          setCurrentNetwork(_evt.target.value);

                          metamaskSwitchNetwork(_evt.target.value);
                        }}
                        value={currentNetwork}
                      >
                        {generateNetworkOptions()}
                      </select>
                    </li>
                    <li>
                      <Link to="/profile" className="nav-link text-black">
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/stats" className="nav-link text-black">
                        Stats
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://docs.artbox.page/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link text-black"
                      >
                        Documentation
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </header>
        </div>

        <Symfoni
          autoInit={true}
          showLoading={false}
          loadingComponent={<h1>LOADING...</h1>}
          network={currentNetwork}
          currency={getChainConfigration(currentNetwork)!.nativeCurrency.symbol}
        >
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/stats" component={Stats} />
          <Route exact path="/artbox" component={ArtBox} />
          <Route exact path="/artbox/:id" component={ArtBox} />
          <Route exact path="/" component={Main} />
        </Symfoni>
      </div>
    </Router>
  );
}

export default App;
