import React, { useRef, useContext, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useCallbackRef } from "use-callback-ref";
import {
  SymfoniContext,
  CurrentAddressContext,
  ArtBoxContext,
} from "./../hardhat/SymfoniContextStatic";
import { ethers } from "ethers";
import Alert from "react-bootstrap/Alert";

interface Props {}

type Coordinates = {
  x: number;
  y: number;
};

export const ArtBox: React.FC<Props> = () => {
  const { id } = useParams();
  const history = useHistory();
  const [, forceUpdate] = useState();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const emptyAddress = "0x0000000000000000000000000000000000000000";
  const [currentAddress] = useContext(CurrentAddressContext);
  const artbox = useContext(ArtBoxContext);
  const symfoni = useContext(SymfoniContext);
  const [currentPrice, setCurrentPrice] = useState(ethers.BigNumber.from(0));
  const [currentOwner, setCurrentOwner] = useState("");
  const [currentStatus, setCurrentStatus] = useState(false);
  const [alertTimeout, setAlertTimeout] = useState(5000);
  const pixelSize = 32;
  const size = 512;
  // const canvasSize = size * pixelSize;
  const query = new URLSearchParams(useLocation().search);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasObj = canvasRef.current;
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [boxPosition] = useState<Coordinates>({
    x: Number(query.get("x")),
    y: Number(query.get("y")),
  });
  const color = useCallbackRef<string>("#0ea340", (_color) =>
    forceUpdate(_color)
  );
  const colors = useCallbackRef<string[]>(["#0ea340"], (_color) =>
    forceUpdate(_color)
  );

  const defaultBoxColors: any = Array.from(
    Array<ethers.BigNumber>(16).fill(ethers.BigNumber.from("16777215")),
    () =>
      new Array<ethers.BigNumber>(16).fill(ethers.BigNumber.from("16777215"))
  );

  const [boxColors, setBoxColors] = useState(defaultBoxColors);

  useEffect(() => {
    let mouseDown: boolean = false;

    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext("2d");
      setContext(renderCtx);
    }

    // Mark the grid
    if (context) {
      // context.beginPath();
      context.strokeStyle = "#FFFFFF";
      // context.lineWidth = 1;
      // mark each box
      // let step = 1;
      // for (let i = 0; i < size + 1; i++) {
      //   step = i * pixelSize;
      //   context.moveTo(step, 0);
      //   context.lineTo(step, canvasSize);
      //   context.moveTo(0, step);
      //   context.lineTo(canvasSize, step);
      // }
      // context.stroke();
    }

    const doAsync = async () => {
      if (!artbox.instance) return;
      if (!context) return;
      console.log(
        "Artbox.tsx: ArtBox is deployed at ",
        artbox.instance.address
      );

      let _box: any;

      if (id) {
        try {
          _box = await artbox.instance.getBoxById(id);
        } catch (error) {
          if (!_box) {
            history.push("/");
            return;
          }
        }
      } else {
        try {
          _box = await artbox.instance.getBoxByCoordinates(
            boxPosition.x,
            boxPosition.y
          );
        } catch (error) {
          console.log(error.message);
        }
      }

      try {
        let _minter = await _box!.minter;
        let _currentOwner: string;
        if (!_minter) {
          _currentOwner = await artbox.instance.ownerOf(_box.id);
        } else {
          _currentOwner = _minter;
        }
        if (_currentOwner !== emptyAddress) {
          for (let i = 0, len = _box.box.length; i < len; i++) {
            for (let j = 0, len = _box.box[i].length; j < len; j++) {
              const _colorCode = _box.box[i][j].toString(16).padStart(6, "0");
              let _currentColor: string;
              if (_colorCode === "0") {
                _currentColor = "#000000";
              } else {
                _currentColor = "#" + _colorCode;
              }
              boxColors[i][j] = ethers.BigNumber.from(_box.box[i][j]);
              context!.fillStyle = _currentColor;
              context!.fillRect(
                i * pixelSize,
                j * pixelSize,
                pixelSize,
                pixelSize
              );
            }
          }
          setBoxColors(boxColors);
          setCurrentOwner(await artbox.instance.ownerOf(_box.id));
          setCurrentStatus(_box.locked);
        }
        const price = await artbox.instance.getCurrentPrice();
        setCurrentPrice(price);
      } catch (e) {
        setVariant("danger");
        setMessage(`There was an error see more: ${e.message}`);
        setShow(true);
      }
    };
    try {
      doAsync();
    } catch (e) {
      console.log(e);
    }

    function handleMouseDown(evt: MouseEvent) {
      mouseDown = true;

      let _boxPosition: Coordinates = {
        x: Math.floor(evt.offsetX / pixelSize),
        y: Math.floor(evt.offsetY / pixelSize),
      };

      context!.fillStyle = color.current!;
      context!.fillRect(
        _boxPosition.x * pixelSize,
        _boxPosition.y * pixelSize,
        32,
        32
      );

      try {
        if (
          (_boxPosition.x >= 0 || _boxPosition.x <= 15) &&
          (_boxPosition.y >= 0 || _boxPosition.y <= 15)
        ) {
          const _color = color.current!.replace("#", "0x");
          boxColors[_boxPosition.x][_boxPosition.y] =
            ethers.BigNumber.from(_color);
          setBoxColors(boxColors);
        }
      } catch (e) {
        console.log(
          "Out of position " + _boxPosition.x + " message: " + e.message
        );
      }
    }

    function handleMouseUp(evt: MouseEvent) {
      mouseDown = false;

      if (!colors.current!.find((o) => o === color.current!)) {
        colors.current!.push(color.current!);
      }
    }

    function handleMouseMove(evt: MouseEvent) {
      if (mouseDown && context) {
        handleMouseDown(evt);
      }
    }

    if (canvasRef.current) {
      if (context && canvasObj) {
        canvasObj.addEventListener("mousedown", handleMouseDown);
        canvasObj.addEventListener("mouseup", handleMouseUp);
        canvasObj.addEventListener("mousemove", handleMouseMove);
      }
    }

    return () => {
      if (canvasObj) {
        canvasObj.removeEventListener("mousedown", handleMouseDown);
        canvasObj.removeEventListener("mouseup", handleMouseUp);
        canvasObj.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, [context, artbox, canvasRef, canvasObj]);

  function createSelectColors() {
    const items = [];

    for (let i = 0; i < colors.current!.length; i++) {
      items.push(
        <option
          key={colors.current![i].toUpperCase()}
          value={colors.current![i].toUpperCase()}
          style={{ backgroundColor: colors.current![i] }}
        >
          {colors.current![i]}
        </option>
      );
    }
    return items;
  }

  const uploadImage = async (id: string) => {
    const canvasimage = canvasRef
      .current!.toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    await fetch(
      `https://api.artbox.workers.dev/${symfoni.network}/image/${id}`,
      {
        method: "post",
        body: JSON.stringify({ filebase64: canvasimage, filename: id }),
      }
    );
  };

  const handleCreateArtBox = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!artbox.instance) throw Error("ArtBox instance not ready");
    if (artbox.instance) {
      try {
        const price = await artbox.instance.getCurrentPrice();

        validateBoxColors(boxColors);

        setVariant("primary");
        setAlertTimeout(10000);
        setMessage(
          `Please wait until the transaction is complete before closing the window...`
        );
        setAlertTimeout(5000);
        setShow(true);
        const tx = await artbox.instance.createBox(
          boxPosition.x,
          boxPosition.y,
          boxColors,
          {
            value: price,
          }
        );
        await tx.wait();

        const _box = await artbox.instance.getBoxByCoordinates(
          boxPosition.x,
          boxPosition.y
        );

        const _id = parseInt(_box.id.toString(), 16).toString();

        uploadImage(_id);
        setVariant("success");
        setMessage(`Congratulations! you now own an ArtBox! tx: ${tx.hash}`);
        setShow(true);
        setCurrentOwner(currentAddress);
      } catch (e) {
        setVariant("danger");
        setMessage(`There was an error see more: ${e.message}`);
        setShow(true);
      }
    }
  };

  const handleUpdateArtBox = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!artbox.instance) throw Error("ArtBox instance not ready");
    if (artbox.instance) {
      try {
        const _box = await artbox.instance.getBoxByCoordinates(
          boxPosition.x,
          boxPosition.y
        );
        let _minter = await _box.minter;
        let _currentOwner: string;
        if (!_minter) {
          _currentOwner = await artbox.instance.ownerOf(_box.id);
        } else {
          _currentOwner = _minter;
        }

        setVariant("primary");
        setAlertTimeout(10000);
        setMessage(
          `Please wait until the transaction is complete before closing the window...`
        );
        setAlertTimeout(5000);
        setShow(true);
        if (_currentOwner === currentAddress) {
          validateBoxColors(boxColors);
          const tx = await artbox.instance.updateBox(
            boxPosition.x,
            boxPosition.y,
            boxColors
          );
          await tx.wait();

          uploadImage(_box.id.toString());
          setVariant("success");
          setMessage(`ArtBox updated! tx: ${tx.hash}`);
          setShow(true);
        }
      } catch (e) {
        setVariant("danger");
        setMessage(`There was an error see more: ${e.message}`);
        setShow(true);
      }
    }
  };

  const handleSetLock = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!artbox.instance) throw Error("ArtBox instance not ready");
    if (artbox.instance) {
      try {
        const lockPrice = await artbox.instance.getCurrentLockPrice();

        const _box = await artbox.instance.getBoxByCoordinates(
          boxPosition.x,
          boxPosition.y
        );

        let _minter = await _box.minter;
        let _currentOwner: string;
        if (!_minter) {
          _currentOwner = await artbox.instance.ownerOf(_box.id);
        } else {
          _currentOwner = _minter;
        }

        if (_currentOwner === currentAddress) {
          const tx = await artbox.instance.lockBox(_box.x, _box.y, {
            value: lockPrice,
          });
          await tx.wait();

          setVariant("success");
          setMessage(`ArtBox LOCKED! tx: ${tx.hash}`);
          setShow(true);
          setCurrentStatus(true);
        }
      } catch (e) {
        setVariant("danger");
        setMessage(`There was an error see more: ${e.message}`);
        setShow(true);
      }
    }
  };

  const handleTransferBox = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!artbox.instance) throw Error("ArtBox instance not ready");
    if (artbox.instance) {
      try {
        const _box = await artbox.instance.getBoxByCoordinates(
          boxPosition.x,
          boxPosition.y
        );

        let _minter = await _box.minter;
        let _currentOwner: string;
        if (!_minter) {
          _currentOwner = await artbox.instance.ownerOf(_box.id);
        } else {
          _currentOwner = _minter;
        }

        const enteredAddress =
          prompt("Please enter the destination address") || currentAddress;

        if (_currentOwner === currentAddress) {
          const tx = await artbox.instance[
            "safeTransferFrom(address,address,uint256)"
          ]!(currentAddress, enteredAddress, _box.id);
          await tx.wait();

          setVariant("success");
          setMessage(`ArtBox transferred! tx: ${tx.hash}`);
          setShow(true);
          setCurrentStatus(true);
        }
      } catch (e) {
        setVariant("danger");
        setMessage(`There was an error see more: ${e.message}`);
        setShow(true);
      }
    }
  };

  function AlertWithMessage(variant: string, message: string) {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, alertTimeout);

      return (
        <Alert variant={variant} onClose={() => setShow(false)} dismissible>
          <p>{message}</p>
        </Alert>
      );
    }
  }

  function validateBoxColors(boxColors: any) {
    boxColors.length = 16;
    for (let i = 0, len = boxColors.length; i < len; i++) {
      boxColors[i].length = 16;
    }
    setBoxColors(boxColors);
  }

  return (
    <div className="App">
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="text-wrap">{AlertWithMessage(variant, message)}</div>
        </div>
      </div>
      <canvas
        id="canvasArtBox"
        ref={canvasRef}
        width={size}
        height={size}
        className="canvasArtBox border"
      ></canvas>

      <div className="canvasArtBoxInfo">
        <HexColorPicker
          color={color.current!}
          onChange={(_color) => {
            color.current = _color;
          }}
        />

        <select
          className="App-color-picker-input"
          onChange={(_evt) => {
            color.current = _evt.target.value;
          }}
        >
          {createSelectColors()}
        </select>

        <div className="artboxInfoSection">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <b>Coordinates:</b> [{boxPosition.x}, {boxPosition.y}]
            </li>
            <li className="list-group-item">
              <b>Current price:</b> {ethers.utils.formatEther(currentPrice)}
              <span> </span>
              {symfoni.currency}
            </li>
            <li className="list-group-item">
              <b>Owner:</b> {currentOwner ? currentOwner : "0x0"}
            </li>
          </ul>

          {(() => {
            if (
              currentOwner.toString() === currentAddress.toString() &&
              currentStatus === false
            ) {
              return (
                <div className="artBoxButtons">
                  <button onClick={(e) => handleSetLock(e)}>Lock</button>
                  <br />

                  <button onClick={(e) => handleUpdateArtBox(e)}>Update</button>
                  <br />

                  <button onClick={(e) => handleTransferBox(e)}>
                    Transfer
                  </button>
                  <br />
                </div>
              );
            } else if (
              (currentOwner.toString() !== currentAddress.toString() &&
                currentOwner.toString() !== "") ||
              currentStatus === true
            ) {
              return <div></div>;
            } else {
              return (
                <div className="artBoxButtons">
                  <button onClick={(e) => handleCreateArtBox(e)}>Buy</button>
                  <br />
                </div>
              );
            }
          })()}
        </div>
      </div>
    </div>
  );
};
