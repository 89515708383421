import React, { useContext, useState } from "react";
import {
  SymfoniContext,
  ProviderContext,
  ArtBoxContext,
  CurrentAddressContext,
} from "./../hardhat/SymfoniContextStatic";
import { ethers } from "ethers";

interface Props {}

export const Stats: React.FC<Props> = () => {
  const [currentAddress] = useContext(CurrentAddressContext);

  const artbox = useContext(ArtBoxContext);
  const symfoni = useContext(SymfoniContext);
  const providerContext = useContext(ProviderContext);
  const provider = providerContext[0];
  const [totalSupply, setTotalSupply] = useState(0);
  const [remainingSupply, setRemainingSupply] = useState(0);
  const [currentPrice, setCurrentPrice] = useState("");
  const [currentLockPrice, setCurrentLockPrice] = useState("");
  const [currentDAOFunds, setCurrentDAOFunds] = useState("");
  const [contractAddress, setContractAddress] = useState("");

  React.useEffect(() => {
    const doAsync = async () => {
      if (!artbox.instance) return;
      setContractAddress(artbox.instance.address);

      const _totalSupply = await artbox.instance.totalSupply();
      setTotalSupply(_totalSupply.toNumber());
      setRemainingSupply(784 - _totalSupply.toNumber());

      const _currentPrice = await artbox.instance.getCurrentPrice();
      setCurrentPrice(ethers.utils.formatEther(_currentPrice));

      const _currentLockPrice = await artbox.instance.getCurrentLockPrice();
      setCurrentLockPrice(ethers.utils.formatEther(_currentLockPrice));

      const _currentDAOAddress = await artbox.instance.getCurrentFundDAO();
      const _currentDAOFunds = await provider!.getBalance(_currentDAOAddress);
      setCurrentDAOFunds(ethers.utils.formatEther(_currentDAOFunds));
    };
    doAsync();
  }, [currentAddress, artbox.instance, provider]);

  function getChainExplorer(_network: string) {
    switch (_network) {
      case "ethereum":
        return "https://etherscan.io";
      case "ropsten":
        return "https://ropsten.etherscan.io";
      case "bsc":
        return "https://bscscan.com";
      case "polygon":
        return "https://explorer-mainnet.maticvigil.com";
      case "fantom":
        return "https://ftmscan.com";
      case "ganache":
        return "http://localhost:7545";
      case "mumbai":
        return "https://explorer-mumbai.maticvigil.com";
      case "harmony":
        return "https://explorer.harmony.one";
      case "arbitrum":
        return "https://arbiscan.com";
    }
  }

  return (
    <div className="App">
      <span className="text-center fs-1">Stats</span> <br />
      <div className="row">
        <div className="col-md-8 mx-auto text-center">
          <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Funds in the DAO</th>
                <td>
                  {currentDAOFunds} {symfoni.currency}
                </td>
              </tr>
              <tr>
                <th scope="row">Total minted boxes</th>
                <td>{totalSupply}</td>
              </tr>
              <tr>
                <th scope="row">Remaining supply</th>
                <td>{remainingSupply}</td>
              </tr>
              <tr>
                <th scope="row">Current price</th>
                <td>
                  {currentPrice} {symfoni.currency}
                </td>
              </tr>
              <tr>
                <th scope="row">Lock price</th>
                <td>
                  {currentLockPrice} {symfoni.currency}
                </td>
              </tr>
              <tr>
                <th scope="row">Contract</th>
                <td>
                  <a
                    href={`${getChainExplorer(
                      symfoni.network
                    )}/address/${contractAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contractAddress}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
