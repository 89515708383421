import React, { useContext, useState } from "react";
import {
  ArtBoxContext,
  SymfoniContext,
  CurrentAddressContext,
} from "./../hardhat/SymfoniContextStatic";
import { useCallbackRef } from "use-callback-ref";
import { Link } from "react-router-dom";

interface Props {}

export const Profile: React.FC<Props> = () => {
  const [currentAddress] = useContext(CurrentAddressContext);

  const [, forceUpdate] = useState();
  const artbox = useContext(ArtBoxContext);
  const boxes = useCallbackRef<any>([], (_boxes) => forceUpdate(_boxes));
  const [currentBalance, setCurrentBalance] = useState(0);
  const [links, setLinks] = useState<any>([]);
  const ownedBoxes = useCallbackRef<any>([], (_boxes) => forceUpdate(_boxes));
  const symfoni = useContext(SymfoniContext);

  React.useEffect(() => {
    const doAsync = async () => {
      if (!artbox.instance) return;

      boxes.current = await artbox.instance.getBoxes();

      const _currentBalance = await artbox.instance.balanceOf(currentAddress);
      setCurrentBalance(_currentBalance.toNumber());

      for (let i = 0, leni = boxes.current.length; i < leni; i++) {
        let _box = boxes.current[i];
        let _minter = await _box.minter;
        let _currentOwner = "0x0";
        if (!_minter) {
          _currentOwner = await artbox.instance.ownerOf(_box.id);
        }

        if (_currentOwner === currentAddress) {
          let id = _box.id.toNumber();

          ownedBoxes.current!.push(
            <div className="col-md-4" key={`col-${id}`}>
              <figure className="figure" key={`figure-${id}`}>
                <Link to={`/artbox?x=${_box.x}&y=${_box.y}`} key={`link-${id}`}>
                  <img
                    src={`https://api.artbox.workers.dev/${symfoni.network}/image/${id}`}
                    className="figure-img img-fluid rounded img-profile"
                    alt="Artbox #{id}"
                    key={`image-${id}`}
                  />
                  <figcaption className="figure-caption" key={`caption-${id}`}>
                    Artbox #{id}
                  </figcaption>
                  [{_box.x}, {_box.y}]
                </Link>
              </figure>
            </div>
          );
        }
      }
      setLinks(ownedBoxes.current);
    };
    doAsync();
  }, [boxes, ownedBoxes, currentAddress, artbox.instance]);

  return (
    <div className="App">
      <span className="text-center fs-1">Profile</span> <br />
      <span className="text-center">
        You own: {currentBalance} {currentBalance === 1 ? "box" : "boxes"}
      </span>
      <div className="container">
        <div className="row">{links}</div>
      </div>
    </div>
  );
};
